import "./scheduleTable.scss";
import useLadyService from "@/services/LadyService";
import { Icon } from "@/components/ui";

const ScheduleTable = ({ props }) => {
  const { workTime, prices, discounts } = props;

  const { minutesToDhms, convertTime, t, lang } = useLadyService();

  const checkDay = {
    1: t("mon"),
    2: t("tue"),
    3: t("wed"),
    4: t("thu"),
    5: t("fri"),
    6: t("sat"),
    7: t("sun"),
  };

  function transformPrices(prices) {
    const transformed = {};

    Object.keys(prices).forEach((type) => {
      prices[type].forEach((item) => {
        const { duration, price, id } = item;

        if (!transformed[duration]) {
          transformed[duration] = [];
        }

        transformed[duration].push({
          price,
          type,
          id,
        });
      });
    });

    return Object.keys(transformed).map((duration) => ({
      [duration]: transformed[duration],
    }));
  }

  const pricesArr = transformPrices(prices);

  function getTypeInfo(data) {
    const typeInfo = {};
    data.forEach((entry) => {
      Object.values(entry).forEach((durationInfo) => {
        durationInfo.forEach((item) => {
          const { type, price, id } = item;
          if (!typeInfo[type]) {
            typeInfo[type] = [];
          }
          typeInfo[type].push({ price, id });
        });
      });
    });
    return typeInfo;
  }

  const typeInfo = getTypeInfo(pricesArr);
  const isIcall = !!typeInfo["incall"]?.length;
  const isOutcall = !!typeInfo["outcall"]?.length;
  const isEscort = !!typeInfo["escort"]?.length;

  return (
    <>
      <div className={`schedule-table`}>
        {!!pricesArr.length ? <table className="schedule-table__prices">
          <thead>
            <tr className="schedule-table__row schedule-table__header ">
                <th className={"color-main schedule-table__time"}>{t("time")}</th>
                {isIcall && (
                  <th className={"schedule-table__title"}>{t("incall")}</th>
                )}
                {isOutcall && (
                  <th className={"schedule-table__title"}>{t("onclient")}</th>
                )}
                {isEscort && (
                  <th className={"schedule-table__title"}>
                    {t("accompaniment")}
                  </th>
                )}
            </tr>
          </thead>
          <tbody>
            {pricesArr.map((data, index) => {
                  const keys = Object.keys(data);
                  const values = Object.values(data).flat();
      
                  function arrayToObject(arr) {
                    const obj = {};
                    arr.forEach((item) => {
                      obj[item.type] = item;
                    });
                    return obj;
                  }
      
                  const newValues = arrayToObject(values);
      
                  return (
                    <tr key={index + 1} className="schedule-table__row">
                      <td>{minutesToDhms(keys[0])}</td>
      
                      {isIcall ? (
                        !!newValues["incall"] && newValues["incall"].price !== 0 ? (
                          <td>{newValues["incall"].price}{lang === "cz" ? " Kč" : " €"}</td>
                        ) : (
                          <td>&mdash;</td>
                        )
                      ) : null}
      
                      {isOutcall ? (
                        !!newValues["outcall"] && newValues["outcall"].price !== 0 ? (
                          <td>{newValues["outcall"].price}{lang === "cz" ? " Kč" : " €"}</td>
                        ) : (
                          <td>&mdash;</td>
                        )
                      ) : null}
      
                      {isEscort ? (
                        !!newValues["escort"] && newValues["escort"].price !== 0 ? (
                          <td>{newValues["escort"].price}{lang === "cz" ? " Kč" : " €"}</td>
                        ) : (
                          <td>&mdash;</td>
                        )
                      ) : null}
                    </tr>
                  );
                })}
          </tbody>
        </table> : null}
        {!!workTime.length || !!discounts.length ? (
            <div className={"schedule-table__wrapper"}>
              {!!workTime.length ? (
                <table className="schedule-table__work-time p2">
                  {workTime.map((data, index) => {
                    return (
                      <tr key={index + 1}>
                        <td className={"title schedule-table__day"}>
                          {checkDay[data.week]}
                        </td>
                        <td className={"schedule-table__work-hours"}>
                          {convertTime(data.start_time) === "00:00" &&
                          convertTime(data.end_time) === "00:00" ? (
                            <>
                              <Icon
                                spritePath={"time-plan"}
                                size={"xxxl"}
                                clazz={"color-green"}
                              />
                              {t("nonstop")}
                            </>
                          ) : (
                            `${convertTime(data.start_time)}-${convertTime(
                              data.end_time
                            )}`
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </table>
              ) : null}
    
              {!!discounts.length ? (
                <table className="schedule-table__discounts">
                  {discounts.map((item, index) => {
                    return (
                      <tr className="schedule-table__discount" key={index + 1}>
                        <td className={"title"}>-{item.discount}%</td>
    
                        <td className={"fz-13"}>{item.description}</td>
    
                        {item.from && item.to ? (
                          <td className={"fz-13"}>
                            {t("with")} {convertTime(item.from)}
                            <br />
                            {t("until")} {convertTime(item.to)}
                          </td>
                        ) : null}
                      </tr>
                    );
                  })}
                </table>
              ) : null}
            </div>
          ) : null}
      </div>
    </>
  );
  
};

export default ScheduleTable;
