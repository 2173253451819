import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getCountriesData, getUserCountry } from "@/stores/slices/userSlice";

export function useCityChange() {
  const navigate = useNavigate();
  const location = useLocation();
  const allCities = useSelector(getUserCountry);
  const countriesData = useSelector(getCountriesData)
  const slugOfAllCities = allCities.map((city) => city.slug);

  const changeCity = (newCity, new_district) => {
    const { pathname, search } = location;
    const pathParts = pathname.split("/");
    const cityIndex = pathParts.findIndex((part) =>
      slugOfAllCities.includes(part)
    );

    const districtExist = countriesData[0]['cz'].cities.find((city) => city?.slug === newCity)?.districts?.some((dist) => dist?.id == new_district?.id)

    if (cityIndex !== -1) {
      pathParts[cityIndex] = districtExist ? newCity + '_' + new_district?.id : newCity;
      const newPath = pathParts.join("/") + search;
      navigate(newPath);
    }
  };

  return changeCity;
}

export default useCityChange;

// deploy req 