import { useSelector } from "react-redux";
import {useParams} from "react-router-dom";
import { useEffect } from "react";
import containsKeyword from "../helper/containsKeyword";
import useLadyService from "@/services/LadyService";
import {setProfileCount} from "@/stores/slices/mapModelSlice";
import {getIsMainPage, setPageDefault} from "@/stores/slices/modelsState";
import {
  getUserCountry,
  setCity,
  setCountriesData,
  setCurrentCity,
  setLanguagesWords,
  setNationalistWords,
  setServicesWords,
  setCountry,
  setCountrySlug,
  setRegion,
  getUserCurrentCity,
  setLinksCurrentCity,
  setUserCurrentDistrict,
} from "@/stores/slices/userSlice";

import {makeRequest} from "@/services/makeRequest";

const useDefiningCity = (setIsLoading = false) => {
  const {
    dispatch,
    city,
    navigate,
    lang,
    usePrevious
  } = useLadyService()

  const allCities = useSelector(getUserCountry);
  const currentCity = useSelector(getUserCurrentCity);
  const slugOfAllCities = allCities.map((city) => city.slug);
  const {city: cityURLParam} = useParams()
  const cityParam = cityURLParam?.split('_')[0]
  const districtParam = cityURLParam?.split('_')[1]

  const isMainPage = useSelector(getIsMainPage);
  if (setIsLoading) {
    setIsLoading(true)
  }
  const prevLang = usePrevious(lang);
  useEffect(() => {
    try {
      const route = "services";
      const method = "GET";
      if (
        (
          cityParam === undefined || 
          currentCity.slug === cityParam) && 
          currentCity.slug !== undefined && 
          prevLang === lang) {
        return
      }
      makeRequest({route, method}).then((response) => {
        const data = response.data;
        if (!!Object.values(data).length) {
          const citiesData = data.cities;
          const languages = data.languages;
          const nationalities = data.nationalities;
          const services = data.services;
          const country = Object.keys(citiesData)[0];
          const countrySLug = citiesData[country].slug
          dispatch(setLanguagesWords(languages));
          dispatch(setNationalistWords(nationalities));
          dispatch(setServicesWords(services));
          // here to add districts if nesessery
          dispatch(setCountriesData(citiesData));
          const citySegment = cityParam || countrySLug;
          let cities
          if (countrySLug === citySegment) {
             // check for districts
            dispatch(setCountrySlug(countrySLug))
            cities = Object.keys(citiesData).map((key) => {
              const item = citiesData[key]
              return {
                id: item?.id ?? 1,
                title: item.name,
                icon: item.code.toUpperCase(),
                highlight: item.highlight,
                slug: item.slug,
                profiles_count: item?.profiles_count ?? 0,
                sort: item.sort,
                districts: item.districts ?? [],
              };
            });

          } else {
            let cityExist = false;
             // check for districts
            dispatch(setCountrySlug(false))
            cities = citiesData[country].cities.map((item) => {
              if (!cityExist && (item.slug === citySegment)) {
                cityExist = true;
              }
              return {
                id: item.id,
                title: item.name,
                icon: citiesData[country].code.toUpperCase(),
                highlight: item.highlight,
                slug: item.slug,
                profiles_count: item.profiles_count,
                sort: item.sort,
                districts: item.districts ?? [],
              };
            });
            if (!cityExist && isMainPage) {
             console.log('here')

              navigate("/404");
              return;
            }
          }
          let newCity;
          if (citySegment === countrySLug) {
            newCity = cities.find((item) => item.slug === citySegment);
          }
          else{
            newCity = cities.find((item) => item.slug === citySegment);
          }
          if (newCity) {
            dispatch(setProfileCount(newCity.profiles_count));
            dispatch(setCurrentCity(newCity));
            dispatch(setPageDefault());
            if(districtParam){
              const district = newCity.districts.find(dist => dist.id === parseInt(districtParam))
              if (district?.id){
                dispatch(setUserCurrentDistrict(district));
              }
              else{
                navigate("/404");
              }
            }
          } else {
            navigate('/404')
          }
          dispatch(setCountry(cities));
          dispatch(setRegion(citiesData[country].name));
          if (setIsLoading) {
            setIsLoading(false)
          }
        }
      });
    } catch (error) {
      console.log('here')

      console.log('error: ', error);
      navigate('/404')
    }
  }, [cityParam, currentCity, lang]);

  useEffect(() => {
    if (!city && allCities.length > 0) {
      setLinksCurrentCity(false)
      if (cityParam && containsKeyword(cityParam, slugOfAllCities)) {
        const cityBySlug = allCities.filter(
          (city) => city.slug === cityParam
        )[0];
        dispatch(setCity(cityParam));
        if (cityBySlug) {
          dispatch(setCurrentCity(cityBySlug));
        }
      }
    }
  }, [allCities]);
};

export default useDefiningCity;
