import { createSlice, current } from "@reduxjs/toolkit";

let result;
if (typeof  window !== "undefined") {
  result = localStorage.getItem("mode");
}
const theme = result ? result : "dark";
let favorites = {};
if (typeof window !== "undefined") {
  favorites = localStorage.getItem("favorites")
  favorites = JSON.parse(favorites)?.length;
}

const initialState = {
  elitePrice: "",
  userMainInfo: "",
  phone: "",
  email: "",
  tg: "",
  wa: "",
  amount: "100",
  userInfoAlreadyExist: false,
  balance: 0,
  hold: 0,
  userType: "default",
  userStatus: "silver",
  apiUserReady: false,
  videochat: false,
  videochatCamera: false,
  favoriteCounter: favorites ? favorites : 0,
  mode: theme,
  userId: null,
  agency_id: null,
  lang: "en",
  city: "",
  region: "",
  readyToVideochat: 1,
  readyToOrder: 1,
  country: [],
  currentCity: localStorage.getItem('currentCity') ?? {},
  countrySlug: localStorage.getItem('countrySlug') ?? 'czechia',
  mainWords: {},
  servicesWords: {},
  languagesWords: {},
  nationalistWords: {},
  countriesData: {},
  isDataReady: false,
  adFragment: {},
  tariff: {},
  tariff_slug: 'silver',
  videochatBlock: {},
  videochatBlockCanceled: {},
  bookingBlock: {},
  previewsBlock: {},
  videochatAmount: 0,
  bookingAmount: 0,
  previewsAmount: 0,
  tickets: 0,
  newToken: "",
  emailVerifyAt: "",
  isLkPage: false,
  initialData: null,
  incomingTransaction: {},
  incomingTicket: {},
  linksCurrentCity: false,
  currentDistrict: {}
};

const userSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setNewToken: (state, action) => {
      state.newToken = action.payload;
    },
    setElitePrice: (state, action) => {
      state.elitePrice = action.payload;
    },
    setEmailVerifyAt: (state, action) => {
      state.emailVerifyAt = action.payload;
    },
    setUserMainInfo: (state, action) => {
      state.userMainInfo = action.payload;
    },
    setUserInfo: (state, action) => {
      const stateName = action.payload.stateName;
      const value = action.payload.value;
      state[stateName] = value;
    },
    setIncomingTicket: (state, action) => {
      state.incomingTicket = action.payload;
    },
    setUserInfoAlreadyExist: (state) => {
      state.userInfoAlreadyExist = true;
    },
    setRegion: (state, action) => {
      state.region = action.payload;
    },
    setUserAgencyId: (state, action) => {
      state.agency_id = action.payload;
    },
    setLinksCurrentCity: (state, action) => {
      state.linksCurrentCity = action.payload
    },
    setVideochatAmount: (state, action) => {
      if (typeof action.payload === "function") {
        state.videochatAmount = action.payload(state.videochatAmount);
      } else {
        state.videochatAmount = action.payload;
      }
    },
    setBookingAmount: (state, action) => {
      if (typeof action.payload === "function") {
        state.bookingAmount = action.payload(state.bookingAmount);
      } else {
        state.bookingAmount = action.payload;
      }
    },
    setPreviewsAmount: (state, action) => {
      if (typeof action.payload === "function") {
        state.previewsAmount = action.payload(state.previewsAmount);
      } else {
        state.previewsAmount = action.payload;
      }
    },

    setCountriesData: (state, action) => {
      state.countriesData = [action.payload];
    },
    setApiUserReady: (state, action) => {
      state.apiUserReady = action.payload;
    },
    setIsDataReady: (state, action) => {
      state.isDataReady = action.payload;
    },
    setReadyToVideochat: (state, action) => {
      state.readyToVideochat = action.payload !== 1 ? 0 : 1;
    },
    setReadyToOrder: (state, action) => {
      state.readyToOrder = action.payload !== 1 ? 0 : 1;
    },
    setServicesWords: (state, action) => {
      state.servicesWords = action.payload;
    },
    setLanguagesWords: (state, action) => {
      state.languagesWords = action.payload;
    },
    setAdFragment: (state, action) => {
      state.adFragment = action.payload;
    },
    setNationalistWords: (state, action) => {
      state.nationalistWords = action.payload;
    },
    setLang: (state, action) => {
      state.lang = action.payload;
    },
    setCity: (state, action) => {
      state.city = action.payload.toLowerCase();
    },
    setCurrentCity: (state, action) => {
      localStorage.setItem('currentCity', action.payload)
      state.currentCity = action.payload;
    },
    setCountrySlug: (state, action) => {
      localStorage.setItem('countrySlug', action.payload)
      state.countrySlug = action.payload;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    setVideochatStatus: (state, action) => {
      state.videochat = action.payload;
    },

    setVideochatCamera: (state, action) => {
      state.videochatCamera = action.payload;
    },

    plusFavoriteCounter: (state) => {
      state.favoriteCounter += 1;
    },

    minusFavoriteCounter: (state) => {
      state.favoriteCounter -= 1;
    },
    setFavoriteCounter: (state, action) => {
      state.favoriteCounter = action.payload;
    },

    setUserTariff: (state, action) => {
      state.tariff = action.payload;
    },

    setUserTariffSlug: (state, action) => {
      state.tariff_slug = action.payload;
    },
    setUserFreeMinutes: (state, action) => {
      state.tariff.free_minutes = action.payload;
    },
    toggleTheme: (state) => {
      if (typeof window !== 'undefined') {
        localStorage.setItem(
            "mode",
            `${(state.mode = state.mode === "dark" ? "light" : "dark")}`
        );
      }
    },
    setUserStatus: (state, action) => {
      state.userStatus = action.payload;
    },
    setTheme: (state, action) => {
      state.mode = action.payload;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setUserBalance: (state, action) => {
      state.balance = action.payload;
    },
    setUserHold: (state, action) => {
      state.hold = action.payload;
    },
    setUserVideochatBlock: (state, action) => {
      state.videochatBlock = action.payload;
    },
    setIncomingTransaction: (state, action) => {
      state.incomingTransaction = action.payload;
    },
    setUserVideochatCanceledBlock: (state, action) => {
      state.videochatBlockCanceled = action.payload;
    },
    setUserBookingBlock: (state, action) => {
      state.bookingBlock = action.payload;
    },
    setUserPreviewsBlock: (state, action) => {
      state.previewsBlock = action.payload;
    },
    setUserTickets: (state, action) => {
      state.tickets = action.payload;
    },
    setIsLkPage: (state, action) => {
      state.isLkPage = action.payload;
    },
    setInitialData: (state, action) => {
      state.tickets = action.payload;
    },
    setUserCurrentDistrict: (state, action) => {
      state.currentDistrict = action.payload
    }
  },
});

export const {
  setUserCurrentDistrict,
  setUserHold,
  setUserInfoAlreadyExist,
  setRegion,
  setUserAgencyId,
  setFavoriteCounter,
  setApiUserReady,
  setIsDataReady,
  setReadyToVideochat,
  setReadyToOrder,
  setNationalistWords,
  setLanguagesWords,
  setAdFragment,
  setServicesWords,
  setMainWords,
  setLang,
  setCity,
  setCountrySlug,
  setCountry,
  setVideochatStatus,
  setVideochatCamera,
  plusFavoriteCounter,
  minusFavoriteCounter,
  setTheme,
  setUserType,
  setUserId,
  setUserStatus,
  toggleTheme,
  setCurrentCity,
  setUserBalance,
  setCountriesData,
  setUserInfo,
  setUserTariff,
  setUserTariffSlug,
  setUserFreeMinutes,
  setUserVideochatBlock,
  setIncomingTransaction,
  setUserVideochatCanceledBlock,
  setUserBookingBlock,
  setUserPreviewsBlock,
  setVideochatAmount,
  setBookingAmount,
  setPreviewsAmount,
  setUserTickets,
  setUserMainInfo,
  setNewToken,
  setEmailVerifyAt,
  setElitePrice,
  setIsLkPage,
  setInitialData,
  setIncomingTicket,
  setLinksCurrentCity
} = userSlice.actions;

export const getCurrentLinkCity = (state) => state.user.linksCurrentCity;

export const getUserCurrentDistrict = (state) => state.user.currentDistrict;

export const getUserMainInfo = (state) => state.user.userMainInfo;

export const themeMode = (state) => state.user.mode;

export const getNewToken = (state) => state.user.newToken;

export const getEmailVerifyAt = (state) => state.user.emailVerifyAt;

export const getUserLoadStatus = (state) => state.user.userInfoAlreadyExist;

export const getUserPhone = (state) => state.user.phone;

export const getUserEmail = (state) => state.user.email;

export const getUserTg = (state) => state.user.tg;

export const getUserWa = (state) => state.user.wa;

export const getUserAmount = (state) => state.user.amount;

export const getVideochatStatus = (state) => state.user.videochat;

export const getUserVideochatBlock = (state) => state.user.videochatBlock;

export const getIncomingTransaction = (state) => state.user.incomingTransaction;

export const getUserVideochatCanceledBlock = (state) =>
    state.user.videochatBlockCanceled;

export const getUserBookingBlock = (state) => state.user.bookingBlock;

export const getUserPreviewsBlock = (state) => state.user.previewsBlock;

export const getVideochatCameraStatus = (state) => state.user.videochatCamera;

export const getFavoriteCounterStatus = (state) => state.user.favoriteCounter;

export const getUserID = (state) => state.user.userId;

export const getAgencyID = (state) => state.user.agency_id;

export const getUserTariff = (state) => state.user.tariff;

export const getUserTariffSlug = (state) => state.user.tariff_slug;

export const getUserType = (state) => state.user.userType;

export const getApiUserReady = (state) => state.user.apiUserReady;

export const getUserStatus = (state) => state.user.userStatus;

export const getUserCountry = (state) => state.user.country;

export const getUserLang = (state) => state.user.lang;

export const getUserVideoStatus = (state) => state.user.readyToVideochat;

export const getUserOrderStatus = (state) => state.user.readyToOrder;

export const getUserCity = (state) => state.user?.city;

export const getUserBalance = (state) => state.user.balance;

export const getUserHold = (state) => state.user.hold;

export const getUserCurrentCity = (state) => state.user.currentCity;

export const getCountrySlug = (state) => state.user.countrySlug;

export const getMainWords = (state) => state.user.mainWords;

export const getServicesWords = (state) => state.user.servicesWords;

export const getLanguagesWords = (state) => state.user.languagesWords;

export const getAdFragment = (state) => state.user.adFragment;

export const getNationalistWords = (state) => state.user.nationalistWords;

export const getIsDataReady = (state) => state.user.isDataReady;

export const getCountriesData = (state) => state.user.countriesData;

export const getUserRegion = (state) => state.user.region;

export const getVideochatAmount = (state) => state.user.videochatAmount;

export const getBookingAmount = (state) => state.user.bookingAmount;

export const getPreviewsAmount = (state) => state.user.previewsAmount;

export const getUserTickets = (state) => state.user.tickets;

export const getUserElitePrice = (state) => state.user.elitePrice;

export const getInitialData = (state) => state.user.initialData;

export const getIsLkPage = (state) => state.user.isLkPage;

export const getIncomingTicket = (state) => state.user.incomingTicket;

export default userSlice.reducer;
