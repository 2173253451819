import useLadyService from "@/services/LadyService";
import { hideShort } from "@/stores/slices/showFilterSlice";

const ShortFilterData = () => {
  const { t, isAgencyPage, lang, dispatch } = useLadyService();
  const options = [
    {
      id: 124,
      title: t("incall").replaceAll(' ', `\u00A0`),
      name: "status",
      value: "incall",
      checked: false,
      forDisabled: "placeIncall",
      translateKey: "incall",
    },
    {
      id: 22,
      title: t("elite"),
      name: "status",
      value: "elitelady",
      checked: false,
      forDisabled: "statusElite",
      translateKey: "elite",
    },
    {
      id: 33,
      title: t("star"),
      name: "status",
      value: "pornstar",
      checked: false,
      forDisabled: "statusPornstar",
      translateKey: "star",
    },
    {
      id: 44,
      title: t("online"),
      name: "status",
      value: "online",
      checked: false,
      forDisabled: "statusOnline",
      translateKey: "online",
    },
    {
      id: 66,
      title: t("new"),
      name: "status",
      value: "new",
      checked: false,
      forDisabled: "statusNew",
      translateKey: "new",
    },

    {
      id: 160,
      title: t("videochat"),
      name: "status",
      value: "vchat",
      checked: false,
      forDisabled: "tagVideochat",
      translateKey: "videochat",
    },
    {
      id: 180,
      title: t("onlineorder"),
      name: "status",
      value: "booking",
      checked: false,
      forDisabled: "tagBooking",
      translateKey: "onlineorder",
    },
    {
      id: 181,
      title: t("erocontent"),
      name: "status",
      value: "ero",
      checked: false,
      forDisabled: "tagEro",
      translateKey: "erocontent",
    },
    {
      id: 55,
      title: t("verification"),
      name: "status",
      value: "verified",
      checked: false,
      forDisabled: "statusVerification",
      translateKey: "verification",
    },
    {
      id: 77,
      title: t("healthy"),
      name: "status",
      value: "healthy",
      checked: false,
      forDisabled: "statusHealthControl",
      translateKey: "healthy",
    },
    {
      id: 88,
      title: t("couples"),
      name: "gender",
      value: "couple",
      path: "couple",
      checked: false,
      forDisabled: "genderCouple",
      translateKey: "couples",
    },
    {
      id: 99,
      title: t("bdsm"),
      name: "status",
      value: "bdsm",
      checked: false,
      forDisabled: "statusBDSM",
      translateKey: "bdsm",
    },
    {
      id: 130,
      title: t("trans"),
      name: "gender",
      value: "trans",
      path: "trans",
      checked: false,
      forDisabled: "genderTrans",
      translateKey: "trans",
    },
    {
      id: 120,
      title: t("guys"),
      name: "gender",
      value: "male",
      path: "guys",
      checked: false,
      forDisabled: "genderMale",
      translateKey: "guys",
    },

    // short filter - asian and anal
    
    {
        id: 200,
        title: t('anal-sex'),
        name: "services",
        value: "2",
        path: 'anal',
        checked: false,
        forDisabled: 'statusAnal',
        translateKey: 'anal-sex'
    },
    {
      id: 201,
      title: t('asiatag'),
      name: "nation",
      path: 'asian',
      value: "asian",
      checked: false,
      forDisabled: 'statusAsian',
      translateKey: 'asiatag'
  },
    {
      id: 140,
      title: t("agency"),
      name: "agency",
      value: "agency",
      onClick: () => {
        dispatch(hideShort());
      },
      isLink: `/${lang}/agencies`,
    }
  ];
  if (!isAgencyPage) {
    options.splice(1, 0, {
      id: 11,
      title: t("indi"),
      name: "status",
      value: "indi",
      checked: false,
      forDisabled: "statusIndividual",
      translateKey: "indi",
    });
  }
  return {
    main_title: t("more"),
    options,
  };
};
export default ShortFilterData;
