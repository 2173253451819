import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { number, object, string } from "yup";
import useLadyService from "@/services/LadyService";
import { makeRequest } from "@/services/makeRequest";
import {
  getOneProfileAddress,
  getOneProfileCoords,
  setOneProfileAddress,
  setOneProfileCoords,
} from "@/stores/slices/oneProfileSlice";
import { setUserInfoAlreadyExist } from "@/stores/slices/userSlice";
import {
  Loader,
} from "@/components/ui";
import debounce from "lodash.debounce";
import showToast from "@/components/toast/Toast";
import "./main.scss";
import { Popup } from "../../../../../../popups";
import { removeSubstrings } from "@/helper/removeSubstrings";

import {DefaultButtons, MainPart, PopupButtons} from './components'

const MainGlobal = ({
  changeStep,
  formData,
  setFormData,
  setAvailableSteps,
  cities,
  setData,
  nationalities,
  languages,
  setIsProfileAlreadyCreate,
  isProfileAlreadyCreate = true,
  setFilled,
  rootElem,
  open,
  setOpen,
  changeCityDisable,
}) => {
  const { lang, t, token, dispatch, city } = useLadyService();

  const data = {
    standLang: [
      {
        id: "3",
        short_name: "cz",
        name: !!languages?.length
          ? languages.filter((lang) => lang.short_name === "cz")[0]?.name
          : "",
      },
      {
        id: "2",
        short_name: "en",
        name: !!languages?.length
          ? languages.filter((lang) => lang.short_name === "en")[0]?.name
          : "",
      },
      {
        id: "1",
        short_name: "ru",
        name: !!languages?.length
          ? languages.filter((lang) => lang.short_name === "ru")[0]?.name
          : "",
      },
    ],
  };

  const options = {
    readyTravel: [
      { id: 93, title: t("nearcity"), value: "nearcity" },
      { id: 33, title: t("incountry"), value: "incountry" },
      { id: 32, title: t("ineurope"), value: "ineurope" },
      { id: 31, title: t("inworld"), value: "inworld" },
    ],
    ethnos: [
      { id: 99, title: t("white"), value: "white" },
      { id: 98, title: t("asian"), value: "asian" },
      { id: 97, title: t("african"), value: "african" },
      { id: 96, title: t("arabic"), value: "arabic" },
      { id: 95, title: t("indian"), value: "indian" },
      { id: 94, title: t("tanned"), value: "tanned" },
      { id: 93, title: t("mixed"), value: "mixed" },
      { id: 93, title: t("latin"), value: "latin" },
    ],
  };

  const currentUrl = window.location.href;
  const urlSlug = currentUrl.split("/create/")[1];

  const [isLoading, setIsLoading] = useState(!isProfileAlreadyCreate);

  const isFormDataLength =
    !!Object.values(formData).length && isProfileAlreadyCreate;

  const [isGlobalLoading, setIsGlobalLoading] = useState(false);

  const [activeCountry, setActiveCountry] = useState("cz");
  const address = useSelector(getOneProfileAddress);
  const coords = useSelector(getOneProfileCoords);
  const [langs, setLangs] = useState([]);
  const [langsCode, setLangsCode] = useState([]);
  const [readyTravel, setReadyTravel] = useState("");

  const [formCity, setFormCity] = useState({
    id: "",
    title: "",
    icon: "",
  });
  const [currentActive, setCurrentActive] = useState("");

  const transformedCountries = useMemo(
    () =>
      nationalities?.map((country) => ({
        icon: country.code.toUpperCase(),
        name: country?.name,
        title: country?.name,
        id: country.id,
      })),
    [nationalities]
  );

  function reformatData(data) {
    const result = [];
    for (const item of data) {
      for (const [key, value] of Object.entries(item)) {
        result.push({
          id: parseInt(key),
          short_name: value?.code,
          name: value?.name,
        });
      }
    }
    return result;
  }

  function getCodes(data) {
    const codes = [];
    for (const item of data) {
      for (const value of Object.values(item)) {
        codes.push(value?.code);
      }
    }
    return codes;
  }

  function convertData(data) {
    const result = [];
    Object.keys(data).forEach((key) => {
      const res = {};
      res[key] = {
        name: data[key]?.name,
        code: key,
        cities: data[key].cities,
      };
      result.push(res);
    });
    return result;
  }

  const citiesOptions = useMemo(() => convertData(cities), [cities]);

  const [langsError, setLangsError] = useState("");
  const [cityError, setCityError] = useState("");

  const [phoneValue, setPhoneValues] = useState(
    formData?.phone ? formData.phone : ""
  );


  const [waValue, setWaValues] = useState(
    formData?.whatsapp ? formData.whatsapp : ""
  );

  const [tg, setTg] = useState(formData?.telegram ? formData.telegram : "");
  const [age, setAge] = useState(formData?.age ? formData.age : "");
  const [name, setName] = useState(formData?.name ? formData.name : "");
  const [nick, setNick] = useState(formData?.slug ? formData.slug : "");

  const [slugValue, setSlugValue] = useState(
    formData.slug ? formData.slug : ""
  );

  const formSchema = object().shape({
    name: string().required(),
    nick: string().required(t("required")),
    age: number()
      .transform((value, originalValue) =>
        originalValue === "" ? undefined : value
      )
      .nullable()
      .required(t("required"))
      .min(18, `${t("minimum")} 18`)
      .max(99, `${t("maxval")} 99`)
      .nullable(true),
    tel: string().test("phone-is-empty", t("phonenumerrequired"), function () {
      return !!phoneValue;
    }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const [langPopup, setLangPopup] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [isAddressActive, setIsAddressActive] = useState(false);

  const checkAge = useCallback(
    debounce((value) => {
      if (value >= 18 && value <= 99) {
        clearErrors("age");
      }
      if (!value) {
        clearErrors("age");
      }
      if (value < 18) {
        setError("age", {
          message: `${t("minimum") + " 18"}`,
        });
      }
      if (value > 99) {
        setError("age", {
          message: `${t("maxval") + " 99"}`,
        });
      }
    }, 1500),
    []
  );

  const setAgeValue = (e) => {
    const value = parseInt(e.target.value, 10);

    checkAge(value);
  };

  const [selectedCity, setSelectedCity] = useState(
    !!formData?.city && !!citiesOptions?.length
      ? citiesOptions
          .find((item) => activeCountry in item)
          [activeCountry].cities.filter((item) => {
            return item?.name === formData.city || item.id === formData.city;
          })[0]?.name
      : ""
  );

  const validateData = () => {
    setLangsError("");
    setCityError("");
    if (langs.length === 0) {
      setLangsError(t("required"));
    }
    if (!selectedCity) {
      setCityError(t("required"));
    }
  };

  const [geoError, setGeoError] = useState(false);

  const throwError = (e) => {
    if (
      !e.target.classList.contains("icon--location") &&
      !e.target.classList.contains("button--tetriary")
    ) {
      if (!selectedCity) {
        setGeoError(true);

        setTimeout(() => {
          setGeoError(false);
        }, 2000);
      }
    }
  };

  const sortByName = (arr) => {
    if (Array.isArray(arr)) {
      return [...arr].sort((a, b) => {
        if (a?.name < b?.name) {
          return -1;
        }
        if (a?.name > b?.name) {
          return 1;
        }
        return 0;
      });
    }
  };

  const sortedLanguages = sortByName(languages);

  const [ethnos, setEthnos] = useState("");
  const [nationality, setNationality] = useState(
    !!transformedCountries?.length
      ? transformedCountries.filter((i) => {
          return (
            i.id === formData.nationality || i?.name === formData.nationality
          );
        })[0]?.title
      : ""
  );

  const [nickValue, setNickValue] = useState(
    formData.slug ? `https://lady4love.com/${lang}/profile/${formData.slug}` : ""
  );

  const [mapMarker, setMapMarker] = useState({ lat: null, lng: null });

  useEffect(() => {
    if (!!citiesOptions?.length) {
      const filteredCity = citiesOptions
        .find((item) => activeCountry in item)
        [activeCountry].cities.filter(
          (city) => city?.name === formData.city || city.id === formData.city
        );
      if (filteredCity[0]) {
        setFormCity({ ...filteredCity[0], icon: activeCountry });
      }
    }
  }, []);

  useEffect(() => {
    if (!!languages?.length && !isFormDataLength) {
      const filteredLanguages = languages.filter((language) =>
        formData.languages?.includes(language.id)
      );
      setLangs(filteredLanguages);

      const filteredLangsCode = filteredLanguages.map(
        (lang) => lang.short_name
      );
      setLangsCode(filteredLangsCode);
    }
  }, [formData.languages, languages, isFormDataLength]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const route = `user/info`;
        const method = "GET";

        const data = await makeRequest({ route, method });
        if (data) {
          setData(data.data);
          const result = data.data;
          setPhoneValues(result.phone);
          setWaValues(String(result.whatsapp ?? ""));
          setTg(result.telegram);
          setPhoneValues(result.phone);
          dispatch(setUserInfoAlreadyExist());
          dispatch(setOneProfileAddress(result.address[0]?.address));
          if (result.city_id) {
            setSelectedCity(
              citiesOptions[0].cz.cities.filter(
                (item) => item.id === Number(result.city_id)
              )[0].name
            );
          }
          // setDistrict()
        }
        setIsLoading(false);
      } catch (error) {}
    };

    !urlSlug &&
      token &&
      !isProfileAlreadyCreate &&
      citiesOptions.length &&
      fetchData();
  }, [token, urlSlug, citiesOptions]);

  useEffect(() => {
    if (isFormDataLength) {
      setFormCity({
        id: formData?.city_id,
        title: formData?.city,
        icon: "CZ",
        name: formData?.city,
      });

      setLangs(reformatData(formData.languages));

      setLangsCode(getCodes(formData.languages));

      setCurrentActive(formData.city_id);

      setNickValue(
        formData.slug ? `https://lady4love.com/${lang}/profile/${formData.slug}` : ""
      );

      setMapMarker({
        lat: formData.latitude ? formData.latitude : null,
        lng: formData.longitude ? formData.longitude : null,
      });
    }
  }, [isFormDataLength]);

  useEffect(() => {
    if (formData?.name) {
      setName(formData?.name);
      setNick(formData.slug);
      setAge(formData.age);
      setTg(formData.telegram);
      setReadyTravel(formData.ready_travel);
      setEthnos(formData.skin_color);
    }
  }, []);

  const handleLangOpen = () => {
    setLangPopup((prev) => !prev);
  };

  const [bounds, setBounds] = useState("");

  const removeLang = (code) => {
    const updateLangs = langs.filter((item) => item.short_name !== code);
    const updateLangsCode = langsCode.filter((item) => item !== code);

    setLangs(updateLangs);
    setLangsCode(updateLangsCode);
  };

  const toggleLang = (item) => {
    setLangsError("");
    const lang = {
      id: Number(item.id),
      short_name: item.short_name,
      name: item?.name,
    };
    const langIndex = langs.findIndex(
      (item) => item.short_name === lang.short_name
    );

    if (langIndex === -1) {
      setLangs([...langs, lang]);
      setLangsCode([...langsCode, item.short_name]);
    } else if (langIndex !== -1) {
      const updatedLangs = [...langs];
      updatedLangs.splice(langIndex, 1);
      setLangs(updatedLangs);
      const resultArr = [...langsCode];
      resultArr.splice(langIndex, 1);
      setLangsCode(resultArr);
    }
  };

  const handleLocationClick = () => {
    setIsAddressActive(true);
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(async function (position) {
        const { latitude, longitude } = position.coords;

        setMapMarker({ lat: latitude, lng: longitude });
        try {
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1&accept-language=${lang}`
          );
          const {
            city: sityRes,
            road,
            house_number,
            town,
          } = response.data.address;

          const city = sityRes || town;

          let uncorrectName = false;

          citiesOptions[0][activeCountry].cities.map((item) => {
            if (
              item.name.toLowerCase() !== city.toLowerCase() &&
              !uncorrectName
            ) {
              uncorrectName = true;
              setSelectedCity(
                citiesOptions[0][activeCountry].cities.filter(
                  (city) => city.slug === "prague"
                )[0].name
              );
              setCurrentActive(1);
              return null;
            }
          });
          setBounds({ lat: latitude, lng: longitude });

          const substringsToRemove = [
            "Столица",
            "Capital",
            "The Capital",
            "Hlavní město",
          ];

          if (citiesOptions?.length) {
            if (
              citiesOptions
                .find((item) => activeCountry in item)
                [activeCountry].cities.some((obj) => {
                  return (
                    obj?.name.toLowerCase() ===
                      removeSubstrings(city, substringsToRemove)
                        .trim()
                        .toLowerCase() ||
                    obj.slug.toLowerCase() ===
                      removeSubstrings(city, substringsToRemove)
                        .trim()
                        .toLowerCase()
                  );
                })
            ) {
              const currentCity = citiesOptions
                .find((item) => activeCountry in item)
                [activeCountry].cities.filter((i) => {
                  return (
                    i?.name.toLowerCase() ===
                      removeSubstrings(
                        city,
                        substringsToRemove
                      ).toLowerCase() ||
                    i.slug.toLowerCase() ===
                      removeSubstrings(city, substringsToRemove).toLowerCase()
                  );
                })[0];

              if (currentCity.id) {
                setSelectedCity(currentCity?.name);
                setCurrentActive(currentCity.id);
                setActiveCountry("cz");
                setFormCity({
                  ...currentCity,
                  icon: "CZ",
                });
              }
            }
          }

          dispatch(
            setOneProfileAddress(
              `${road} ${house_number ? ", " + house_number : ""}`
            )
          );
          dispatch(setOneProfileCoords({ latitude, longitude }));
        } catch (error) {
          console.error("Error fetching address:", error);
        }
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      (selectRef.current && !selectRef.current.contains(event.target)) &&
      event.target.id !== 'select-lang-button'
    ) {
      event.stopPropagation();
      setLangPopup(false);
    }
  };

  const selectRef = useRef(null);

  const createSlug = useCallback(
    debounce((value) => {
      const fetchData = async () => {
        try {
          const route = `user/profile/slug/${value}`;
          const method = "GET";

          const data = await makeRequest({ route, method });
          if (data) {
            if (data.status) {
              checkNickIsAvailable(value);
              setValue("nick", value);
              setSlugValue(value);
            }
            if (data.slug) {
              checkNickIsAvailable(data.slug);
              setValue("nick", data.slug);
              setSlugValue(data.slug);
            }
          }
        } catch (error) {}
      };

      token && fetchData();
    }, 1000),
    []
  );

  const createProfileSlug = (e) => {
    createSlug(e.target.value);
  };

  const checkNick = useCallback(
    debounce((value) => {
      const fetchData = async () => {
        try {
          const route = `user/profile/check-slug/${value}`;
          const method = "GET";

          const data = await makeRequest({ route, method });
          if (data) {
            const status = data.status;
            if (!status) {
              const alternative = data.alternative;
              setError("nick", {
                message: `${t("taken")} ${alternative}`,
              });
            } else {
              clearErrors("nick");
              setNick(value)
            }
          }
        } catch (error) {}
      };

      token && fetchData();
    }, 750),
    []
  );

  const checkNickIsAvailable = (value) => {
    let nick = value.substring(value.lastIndexOf("/") + 1);
    nick = nick.replace(/[^a-zA-Z0-9-]/g, "");
    if (nick === "lady4lovecom") {
      setNickValue("");
    } else {
      setNickValue(`https://lady4love.com/${lang}/profile/${nick}`);
    }
    checkNick(nick);
  };

  const onSubmit = (data) => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsGlobalLoading(true);
      console.log('api', district?.id)
      try {
        const route = isProfileAlreadyCreate
          ? `user/profile/${formData.slug}`
          : `user/profile`;
        const method = isProfileAlreadyCreate ? "PUT" : "POST";
        const payload = {
          ...(method === "PUT" && { isFull: true }),
          ...(method === "PUT" && { lang }),
          ...(!!waValue && { whatsapp: String(waValue.replace(/[" "+]/g, ""))}),
          ...(!!data.telegram && { telegram: data.telegram.replace(/^@/, "") }),
          name: data?.name,
          nick,
          ...(method === "POST" && { slug: slugValue }),
          phone:
            typeof phoneValue === "number"
              ? phoneValue
              : Number(phoneValue?.replace(/[" "+]/g, "")),
          age: data.age,
          languages: langs.map((item) => item.id),
          city: citiesOptions
            .find((item) => activeCountry in item)
            [activeCountry].cities.filter(
              (item) => item?.name.toLowerCase() === selectedCity.toLowerCase()
            )[0].id,
          ...(!!address && address[0] && { address: address[0]?.address }),
          ...(!!coords?.latitude && { latitude: coords?.latitude }),
          ...(!!coords?.longitude && { longitude: coords?.longitude }),
          ...(!!district && {district: district?.id}),
          ...(!!ethnos && { skin_color: ethnos }),
          ...(!!nationality && {
            nationality: transformedCountries.filter(
              (i) => i?.name === nationality
            )[0].id,
          }),
          ...(!!readyTravel && { ready_travel: readyTravel }),
        };

        const result = await makeRequest({ route, method, payload });
        if (result.status) {
          setIsLoading(false);
          setIsGlobalLoading(false);
          setFormData({...result.profile,});
          !!setOpen && setOpen(false);
          !!setFilled && setFilled(result.profile.completion);

          if (!isProfileAlreadyCreate) {
            const currentPath = window.location.pathname;
            const newPath = `${currentPath}/${slugValue}`;
            window.history.pushState({}, null, newPath);
            setIsProfileAlreadyCreate(true);
          }
          if (result.profile.slug !== formData.slug) {
            const currentPath = window.location.pathname;
            const newPath = currentPath.replace(formData.slug, result.profile.slug)
            window.history.pushState({}, null, newPath);
          }

          !!changeStep && changeStep((prev) => prev + 1);

          !!setAvailableSteps && setAvailableSteps((prev) => [...prev, 2]);
          !!rootElem &&
            rootElem.scrollIntoView({
              behavior: "smooth",
            });
        }
      } catch (error) {
        showToast({
          message: t("oops"),
          variant: "error",
        });
        setIsLoading(false);
        setIsGlobalLoading(false);
      }
    };
    if (langsError) {
      rootElem.scrollIntoView({
        behavior: "smooth",
      });
    }
    token && !langsError && fetchData();
  };

  useEffect(() => {
    if (isProfileAlreadyCreate && city) {
      setFormData((prev) => ({
        ...prev,
        ...(!!waValue.replace(/[+]/g, "") && {
          whatsapp: String(waValue.replace(/[" "+]/g, "")),
        }),
        ...(!!tg && { telegram: tg.replace(/^@/, "") }),
        name: name,
        nick: nick.substring(nick.lastIndexOf("/") + 1),
        phone:
          typeof phoneValue === "number"
            ? phoneValue
            : Number(phoneValue?.replace(/[" "+]/g, "")),
        age: age,
        languages: langs?.map((item) => ({
          [item.id]: {
            code: item.short_name,
            name: item.name,
          },
        })),
        city: citiesOptions
          .find((item) => activeCountry in item)
          [activeCountry].cities.filter(
            (item) => item?.name.toLowerCase() === selectedCity.toLowerCase()
          )[0]?.id,
        ...(!!address && address[0] && { address: address[0]?.address }),
        ...(!!coords?.latitude && { latitude: coords?.latitude }),
        ...(!!coords?.longitude && { longitude: coords?.longitude }),
        ...(!!ethnos && { skin_color: ethnos }),
        ...(!!nationality && {
          nationality: transformedCountries.filter(
            (i) => i?.name === nationality
          )[0].id,
        }),
        ...(!!readyTravel && { ready_travel: readyTravel }),
      }));
    }
  }, [
    activeCountry,
    address,
    age,
    citiesOptions,
    coords?.latitude,
    coords?.longitude,
    ethnos,
    langs,
    name,
    nationality,
    nick,
    phoneValue,
    readyTravel,
    selectedCity,
    setFormData,
    tg,
    transformedCountries,
    waValue,
    city,
  ]);

  const handleClose = () => {
    setOpen(false);
  };

  const [district, setDistrict] = useState(formData.district)

  const propses = {
    name: name,
    setOpen: setOpen,
    setIsAddressActive: setIsAddressActive,
    register: register,
    errors: errors,
    createProfileSlug: createProfileSlug,
    checkNickIsAvailable: checkNickIsAvailable,
    nickValue: nickValue,
    setAgeValue: setAgeValue,
    formData: formData,
    phoneValue: phoneValue,
    setPhoneValues: setPhoneValues,
    isLoading: isLoading,
    setError: setError,
    waValue: waValue,
    setWaValues: setWaValues,
    setNationality: setNationality,
    transformedCountries: transformedCountries,
    sortByName: sortByName,
    nationality: nationality,
    setEthnos: setEthnos,
    options: options,
    setReadyTravel: setReadyTravel,
    handleLangOpen: handleLangOpen,
    langPopup: langPopup,
    langsError: langsError,
    selectRef: selectRef,
    searchValue: searchValue,
    setSearchValue: setSearchValue,
    data: data,
    langsCode: langsCode,
    toggleLang: toggleLang,
    languages: languages,
    sortedLanguages: sortedLanguages,
    langs: langs,
    removeLang: removeLang,
    citiesOptions: citiesOptions,
    cityError: cityError,
    formCity: formCity,
    selectedCity: selectedCity,
    setSelectedCity: setSelectedCity,
    currentActive: currentActive,
    setActiveCountry: setActiveCountry,
    activeCountry: activeCountry ?? 'cz',
    setCurrentActive: setCurrentActive,
    throwError: throwError,
    address: typeof address === "string" ? address : address ? address[0]?.address : null,
    geoError: geoError,
    bounds: bounds,
    handleLocationClick: handleLocationClick,
    isAddressActive: isAddressActive,
    setBounds: setBounds,
    mapMarker: mapMarker,
    setTg: setTg,
    setName: setName,
    tg: tg,
    setAge: setAge,
    changeCityDisable: changeCityDisable,
    clearErrors: clearErrors,
    setDistrict: setDistrict,
    district: district
  }

  if (!!setOpen) {
    return (
      <>
        {isLoading && (
          <div className="loader__overlay">
            <Loader height={100} />
          </div>
        )}

        <Popup
          open={open}
          setOpen={handleClose}
          onSubmit={handleSubmit(onSubmit)}
          clazz={`popup-form__body__edit`}
        >
          <h2>{t("main")}</h2>

          <MainPart
            {...propses}
            isModal={true}
          />

          <PopupButtons
            setOpen={setOpen}
            isGlobalLoading={isGlobalLoading}
            validateData={validateData}
          />
        </Popup>
      </>
    );
  }

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <h2>{t("main")}</h2>

      <form onSubmit={handleSubmit(onSubmit)}>
        <MainPart
          {...propses}
          setCityError={setCityError}
        />

        <DefaultButtons
          validateData={validateData}
          isGlobalLoading={isGlobalLoading}
          formData={formData}
        />
      </form>
    </>
  );
};

export default MainGlobal;
