import { useNavigate } from "react-router-dom";

export function useLanguageChange() {
  const navigate = useNavigate();

  const pathname = window.location.pathname;
  const languages = ["ru", "en", "cz"];

  const changeLanguage = (newLang) => {
    const pathParts = pathname.split("/");
    const languageIndex = pathParts.findIndex((part) =>
      languages.includes(part)
    );
    // check if districts handling required

    if (languageIndex !== -1) {
      pathParts[languageIndex] = newLang;
      const newPath = pathParts.join('/');
      navigate(newPath);

    }
  };

  return changeLanguage;
}

export default useLanguageChange;
