import React from "react";
import { Select } from "@/components/ui";
import useLadyService from "@/services/LadyService";
import {useSelector} from "react-redux";
import {getUserCurrentDistrict} from "@/stores/slices/userSlice";

const DropdownLanguage = ({ size = "xs" }) => {
    const { city } = useLadyService();
    const currentDistrict = useSelector(getUserCurrentDistrict)
    const isJsdom = typeof window !== 'undefined' && window.navigator.userAgent.includes('jsdom');
    const languages = [
        { id: 1, link: city === 'czechia' ? '/' : currentDistrict ? `/en/${city}_${currentDistrict.slug}` :`/en/${city}`, title: "EN", value: "en" },
        { id: 2, link: currentDistrict ? `/ru/${city}_${currentDistrict.slug}` :`/ru/${city}`, title: "RU", value: "ru" },
        { id: 3, link: currentDistrict ? `/cz/${city}_${currentDistrict.slug}` :`/cz/${city}`, title: "CZ", value: "cz" },
    ]
    if (!isJsdom) {
        return (
            <Select

                options={languages}
                size={size}
                arrowSize={"s"}
                sorted={true}
                name={"language"}
            ></Select>
        )
    } else {
        return (
            languages.map((link) => {
                return (<a href={link.link} key={'link-' + link.title}>{link.title}</a>)
            })
        )
    }
};

export default DropdownLanguage