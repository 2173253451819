import {
  setAuthorizationStatus,
  setStoriesMap,
} from "@/stores/slices/popupSlice";
import React from "react";
import { Button, ButtonLink, Icon } from "@/components/ui";
import useLadyService from "@/services/LadyService";
import { useSelector } from "react-redux";
import {
  getBookingAmount,
  getFavoriteCounterStatus,
  getVideochatAmount,
} from "@/stores/slices/userSlice";
import { show, showShort } from "@/stores/slices/showFilterSlice";
import { getOneModelPosition, getProfilesCount, getProfilesFilterCount, setOneModelPosition } from "@/stores/slices/mapModelSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { getModelsOnMapCount } from "@/stores/slices/counterFilterSlice";
import { getBurgerMenu, setAgencyFilterOpen, setBurgerMenu, setBurgerMenuType } from "../../../stores/slices/headerSlice";
import { useToggleCountryCZ } from "@/components/ui/select/SelectCountry";

const MenuNavBar = ({ lk }) => {
  const { dispatch, userType, t, scrollToTop, lang } = useLadyService();
  const navigate = useNavigate()
  const location = useLocation()
  const modelsOnMapCount = useSelector(getModelsOnMapCount);

  const showFilter = () => {
    dispatch(setBurgerMenu(false));
    dispatch(show());
  };

  const showShortFilter = () => {
    dispatch(setBurgerMenu(false));
    dispatch(showShort());
  };

  const showAgencyFilter = () => {
    dispatch(setAgencyFilterOpen(true))
  }

  const favoriteCount = useSelector(getFavoriteCounterStatus);
  const profilesCountValue = useSelector(getProfilesCount);
  const modelPosition = useSelector(getOneModelPosition);
  const burgerMenuState = useSelector(getBurgerMenu);
  const userOrders = useSelector(getBookingAmount)
  const videoChatAmmount = useSelector(getVideochatAmount)

  const isVideoLocation = location.pathname.includes('lk/videochats')
  const isReservtionLocation = location.pathname.includes('lk/reservations')
  const isAdsLocation = location.pathname.includes(`lk/profiles`)


  const handleProfileClick = () => {
    if (userType === "default") {
      dispatch(setAuthorizationStatus(true))
    }
    else {
      dispatch(setBurgerMenuType(false))
      dispatch(setBurgerMenu(!burgerMenuState))
    }
  }


  const mapOpenHandler = () => {
    if (profilesCountValue > 0 || modelsOnMapCount > 0) dispatch(setStoriesMap(true))
    if (modelPosition) dispatch(setOneModelPosition(modelPosition))
  }

  const toggleCountry = useToggleCountryCZ()


  const headerIconsMenuBar = {
    default: [
      {
        id: 1,
        icon: "home-main",
        name: t("mainpage"),
        link: `/${lang}/czechia`,
        onclick: () => {
          navigate(`/${lang}/czechia`)
          setTimeout(() => {
            scrollToTop();
          }, 500)
        }
      },
      {
        id: 2,
        icon: "catalog",
        name: t("catalogue"),
        onClick: () => showShortFilter(),
        disabled: false
      },
      {
        id: 3,
        icon: "filter",
        name: t("filter"),
        onClick: () => {
          showFilter()
        },
        disabled: false
      },
      {
        id: 4,
        icon: "map",
        name: t("onmap"),
        onClick: () => mapOpenHandler(),
        disabled: modelsOnMapCount === 0 || profilesCountValue === 0,
      },
      {
        id: 5,
        icon: "favorite",
        clazz: `user__favorite${favoriteCount <= 0 ? ' empty' : ''}`,
        name: t("favorites"),
        counter: favoriteCount,
        link: "/history",
      },
      {
        id: 6,
        icon: "person",
        name: t("profuser"),
        onClick: () => handleProfileClick()
      },
    ],
  };

  const agencyAndIndiLkMenuBar = [
    {
      id: 1,
      icon: "home-main",
      name: t("mainpage"),
      link: `/${lang}/czechia`,
      onclick: () => {
        navigate(`/${lang}/czechia`)
        setTimeout(() => {
          scrollToTop();
        }, 500)
      }
    },
    {
      id: 2,
      icon: "user-id",
      name: t("myads"),
      link: "/lk/profiles",
      clazz: `${isAdsLocation ? `color-green` : ``}`
    },
    {
      id: 3,
      icon: "filter",
      name: t("filter"),
      onClick: () => {
        if (location.pathname.includes('/profiles')) {
          showAgencyFilter()
        } else {
          navigate(`/${lang}/lk/profiles`); showAgencyFilter()
        }
      },
      disabled: false
    },
    {
      id: 4,
      icon: "video",
      name: t("videochats"),
      clazz: `user__favorite${videoChatAmmount <= 0 ? ' empty' : ''} ${isVideoLocation ? `color-green` : ``}`,
      counter: videoChatAmmount,
      link: "/lk/videochats",
    },
    {
      id: 5,
      icon: "pay-content",
      clazz: `user__favorite${userOrders <= 0 ? ' empty' : ''} ${isReservtionLocation ? `color-green` : ``}`,
      name: t("myorder"),
      counter: userOrders,
      link: "/lk/reservations",
    },
    {
      id: 6,
      icon: "person",
      name: t("profuser"),
      onClick: () => handleProfileClick()
    },
  ]



  return (
    <nav className="menu__body">
      {
        ((userType === 'agency' || userType === 'indi') && location.pathname.includes('/lk')
          ?
          agencyAndIndiLkMenuBar : headerIconsMenuBar.default).slice(0, 6)
          .map((i) => {
            if (i.link) {
              return (
                <ButtonLink
                  href={i.link}
                  key={i.id}
                  title={i.name}
                  size={"l"}
                  clazz={`menu__link _no-underline${i.clazz ? ` ${i.clazz}` : ""
                    }`}
                  counter={i.wallet ? i.counter : ""}
                  onClick={() => i.onclick?.()}
                >
                  {i.icon === 'wallet' ? (
                    <span className={"menu__wallet currency--erocoin"}>
                      {i.wallet}
                    </span>
                  ) : (
                    <Icon
                      counter={i.counter}
                      size={"l"}
                      clazz={i.clazz}
                      spritePath={i.icon}
                    />
                  )}
                  <span>{i.name}</span>
                </ButtonLink>
              );
            }

            return (
              <Button
                onClick={i.onClick}
                key={i.id}
                title={i.name}
                size={"l"}
                counter={i.counter}
                clazz={`menu__link${i.clazz ? ` ${i.clazz}` : ""}`}
                disabled={i.disabled}
              >
                <Icon size={"l"} clazz={i.clazz} spritePath={i.icon} />
                <span>{i.name}</span>
              </Button>
            );
          })
      }
    </nav>
  );
};

export default MenuNavBar;
